import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LeadSellerActions } from './lead-seller.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { LeadSeller } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class LeadSellerEffects {
  loadLeadSellers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadSellerActions.loadLeadSellers),
      switchMap(() => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'leadsellers'),
            snapshot => {
              const leadsellers = snapshot.docs.map(doc =>
                LeadSeller.fromJSON({ ...doc.data(), id: doc.id })
              );
              subscriber.next(
                LeadSellerActions.loadLeadSellersSuccess({ leadsellers })
              );
            },
            error => {
              subscriber.next(
                LeadSellerActions.loadLeadSellersFailure({ error })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[LeadSeller API] Load LeadSellers Error', error })
          )
        );
      })
    );
  });

  loadLeadSeller$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadSellerActions.loadLeadSeller),
      switchMap(({ leadsellerId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'leadsellers', leadsellerId),
            snapshot => {
              const leadseller = LeadSeller.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(
                LeadSellerActions.loadLeadSellerSuccess({ leadseller })
              );
            },
            error => {
              subscriber.next(
                LeadSellerActions.loadLeadSellerFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addLeadSeller$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadSellerActions.addLeadSeller),
      mergeMap(async ({ leadseller }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'leadsellers'),
            leadseller
          );
          return LeadSellerActions.addLeadSellerSuccess({
            leadseller: LeadSeller.fromJSON({ ...leadseller, id: docRef.id }),
          }); // return new leadseller with id
        } catch (error) {
          return LeadSellerActions.addLeadSellerFailure({ error });
        }
      })
    );
  });

  removeLeadSeller$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadSellerActions.removeLeadSeller),
      mergeMap(async ({ leadsellerId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'leadsellers', leadsellerId));
          return LeadSellerActions.removeLeadSellerSuccess({ leadsellerId }); // return removed leadseller's id
        } catch (error) {
          return LeadSellerActions.removeLeadSellerFailure({ error });
        }
      })
    );
  });

  updateLeadSeller$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadSellerActions.updateLeadSeller),
      mergeMap(async ({ leadsellerId, leadseller }) => {
        try {
          await setDoc(
            doc(this.firestore, 'leadsellers', leadsellerId),
            leadseller,
            {
              merge: true,
            }
          );
          return LeadSellerActions.updateLeadSellerSuccess({
            leadsellerId,
            leadseller,
          }); // return updated leadseller's id and changes
        } catch (error) {
          return LeadSellerActions.updateLeadSellerFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
