import { createFeature, createReducer, on } from '@ngrx/store';
import { EnrollmentSunfireActions } from './enrollment-sunfire.actions';
import { SfWe_Plan } from 'src/app/shared/models';

export const enrollmentSunfireFeatureKey = 'enrollmentSunfire';

export interface EnrollmentSunfireState {
  enrollmentsSunfire: SfWe_Plan[];
  enrollmentSunfire: SfWe_Plan | null;
  loading: boolean;
  error: any;
}

export const initialState: EnrollmentSunfireState = {
  enrollmentsSunfire: [],
  enrollmentSunfire: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    EnrollmentSunfireActions.loadEnrollmentsSunfire,
    (state): EnrollmentSunfireState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentSunfireActions.loadEnrollmentsSunfireSuccess,
    (state, { enrollmentsSunfire }): EnrollmentSunfireState => ({
      ...state,
      enrollmentsSunfire,
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentSunfireActions.loadEnrollmentsSunfireFailure,
    (state, { error }): EnrollmentSunfireState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentSunfireActions.loadEnrollmentSunfire,
    (state): EnrollmentSunfireState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentSunfireActions.loadEnrollmentSunfireSuccess,
    (state, { enrollmentSunfire }): EnrollmentSunfireState => ({
      ...state,
      enrollmentsSunfire: [enrollmentSunfire],
      enrollmentSunfire: enrollmentSunfire,
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentSunfireActions.loadEnrollmentSunfireFailure,
    (state, { error }): EnrollmentSunfireState => ({
      ...state,
      loading: false,
      error,
    })
  )
  //   on(
  //     EnrollmentSunfireActions.addEnrollmentSunfire,
  //     (state): EnrollmentSunfireState => ({
  //       ...state,
  //       loading: true,
  //       error: null,
  //     })
  //   ),
  //   on(
  //     EnrollmentSunfireActions.addEnrollmentSunfireSuccess,
  //     (state, { enrollmentSunfire }): EnrollmentSunfireState => ({
  //       ...state,

  //       enrollmentsSunfire: [...state.enrollmentsSunfire, enrollmentSunfire],

  //       loading: false,

  //       error: null,
  //     })
  //   ),
  //   on(
  //     EnrollmentSunfireActions.addEnrollmentSunfireFailure,
  //     (state, { error }): EnrollmentSunfireState => ({
  //       ...state,
  //       loading: false,
  //       error,
  //     })
  //   ),
  //   on(
  //     EnrollmentSunfireActions.removeEnrollmentSunfire,
  //     (state): EnrollmentSunfireState => ({
  //       ...state,
  //       loading: true,
  //       error: null,
  //     })
  //   ),
  //   on(
  //     EnrollmentSunfireActions.removeEnrollmentSunfireSuccess,
  //     (state, { enrollmentSunfireId }): EnrollmentSunfireState => ({
  //       ...state,
  //       enrollmentsSunfire: state.enrollmentsSunfire.filter(
  //         enrollmentSunfire => enrollmentSunfire.id !== enrollmentSunfireId
  //       ),
  //       loading: false,
  //       error: null,
  //     })
  //   ),
  //   on(
  //     EnrollmentSunfireActions.removeEnrollmentSunfireFailure,
  //     (state, { error }): EnrollmentSunfireState => ({
  //       ...state,
  //       loading: false,
  //       error,
  //     })
  //   ),
  //   on(
  //     EnrollmentSunfireActions.updateEnrollmentSunfire,
  //     (state): EnrollmentSunfireState => ({
  //       ...state,
  //       loading: true,
  //       error: null,
  //     })
  //   ),
  //   on(
  //     EnrollmentSunfireActions.updateEnrollmentSunfireSuccess,
  //     (state, { enrollmentSunfire }): EnrollmentSunfireState => ({
  //       ...state,
  //       enrollmentsSunfire: state.enrollmentsSunfire.map(item =>
  //         // item.id === enrollmentSunfire.id ? enrollmentSunfire : item
  //         item.id === enrollmentSunfire.id
  //           ? { ...item, ...enrollmentSunfire }
  //           : item
  //       ),
  //       loading: false,
  //       error: null,
  //     })
  //   ),
  //   on(
  //     EnrollmentSunfireActions.updateEnrollmentSunfireFailure,
  //     (state, { error }): EnrollmentSunfireState => ({
  //       ...state,
  //       loading: false,
  //       error,
  //     })
  //   )
);

export const enrollmentSunfireFeature = createFeature({
  name: enrollmentSunfireFeatureKey,
  reducer,
});
