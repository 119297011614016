/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Doctor } from "./enrollment";
import { Struct } from "./google/protobuf/struct";

export const protobufPackage = "";

export enum EnrollmentSignatureType {
  PHONE = 0,
  MAIL = 1,
  DIGITAL = 2,
  DIGITAL_WRITTEN = 3,
  UNRECOGNIZED = -1,
}

export function enrollmentSignatureTypeFromJSON(object: any): EnrollmentSignatureType {
  switch (object) {
    case 0:
    case "PHONE":
      return EnrollmentSignatureType.PHONE;
    case 1:
    case "MAIL":
      return EnrollmentSignatureType.MAIL;
    case 2:
    case "DIGITAL":
      return EnrollmentSignatureType.DIGITAL;
    case 3:
    case "DIGITAL_WRITTEN":
      return EnrollmentSignatureType.DIGITAL_WRITTEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EnrollmentSignatureType.UNRECOGNIZED;
  }
}

export function enrollmentSignatureTypeToJSON(object: EnrollmentSignatureType): string {
  switch (object) {
    case EnrollmentSignatureType.PHONE:
      return "PHONE";
    case EnrollmentSignatureType.MAIL:
      return "MAIL";
    case EnrollmentSignatureType.DIGITAL:
      return "DIGITAL";
    case EnrollmentSignatureType.DIGITAL_WRITTEN:
      return "DIGITAL_WRITTEN";
    case EnrollmentSignatureType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface EnrollmentCompat {
  id: string;
  consumerId: string;
  plan?: EnrollmentPlan | undefined;
  applicantInfo?: EnrollmentApplicantInfo | undefined;
  medicareInfo?: EnrollmentMedicareInfo | undefined;
  planInfo?: EnrollmentPlanInfo | undefined;
  reviewSubmit?: EnrollmentReviewSubmit | undefined;
  completed?: EnrollmentCompletedSteps | undefined;
  benefitsConfirmed?: boolean | undefined;
  signatureOption?: string | undefined;
  tpmoId?: string | undefined;
  leadId?: string | undefined;
}

export interface EnrollmentPlan {
  id: string;
  planBenefit: { [key: string]: string };
  summary: EnrollmentPlan_SummaryElement[];
  inpatientCare: EnrollmentPlan_TableElement[];
  outpatientCare: EnrollmentPlan_TableElement[];
  coveragePhase: EnrollmentPlan_CoverageElement[];
  additional: EnrollmentPlan_TableElement[];
}

export interface EnrollmentPlan_PlanBenefitEntry {
  key: string;
  value: string;
}

export interface EnrollmentPlan_PlanBenefit {
  drugsCovered?: string | undefined;
  mpfTemplateContact: EnrollmentPlan_PlanBenefit_MpfTemplateContact[];
  contractId?: string | undefined;
  planName?:
    | string
    | undefined;
  /** optional PlanType type = 5; */
  type?: string | undefined;
  benefitCostPackages: EnrollmentPlan_PlanBenefit_BenefitCostPackage[];
  doctorChoice?: string | undefined;
  url?: string | undefined;
  vbid: EnrollmentPlan_PlanBenefit_VBID[];
  planTypeDesc?: string | undefined;
  premium?: string | undefined;
  costShares: EnrollmentPlan_PlanBenefit_CostShare[];
  planTotalPremiumNetRebate?: string | undefined;
  categories: EnrollmentPlan_PlanBenefit_PlanCategory[];
  sntCategories: EnrollmentPlan_PlanBenefit_SntCategory[];
}

export interface EnrollmentPlan_PlanBenefit_MpfTemplateContact {
}

export interface EnrollmentPlan_PlanBenefit_BenefitCostPackage {
}

export interface EnrollmentPlan_PlanBenefit_VBID {
}

export interface EnrollmentPlan_PlanBenefit_CostShare {
}

export interface EnrollmentPlan_PlanBenefit_PlanCategory {
}

export interface EnrollmentPlan_PlanBenefit_SntCategory {
}

export interface EnrollmentPlan_SummaryElement {
  name?: string | undefined;
  iconClass?: string | undefined;
  value?: string | undefined;
}

export interface EnrollmentPlan_TableElement {
  name?: string | undefined;
  iconClass?: string | undefined;
  services: EnrollmentPlan_TableElement_TableService[];
  groups: EnrollmentPlan_TableElement_TableGroup[];
  copayment?: string | undefined;
  authorizationNeeded?: string | undefined;
  value?: string | undefined;
  limit?: string | undefined;
}

export interface EnrollmentPlan_TableElement_TableGroup {
  name?: string | undefined;
  iconClass?: string | undefined;
  services: EnrollmentPlan_TableElement_TableService[];
  value?: string | undefined;
  authorizationNeeded?: string | undefined;
  limit?: string | undefined;
}

export interface EnrollmentPlan_TableElement_TableService {
  name?: string | undefined;
  value?: string | undefined;
  authorizationNeeded?: string | undefined;
  limit?: string | undefined;
  network?: string | undefined;
}

export interface EnrollmentPlan_CoverageElement {
  name?: string | undefined;
  iconClass?: string | undefined;
  order?:
    | string
    | undefined;
  /**
   * repeated TableService services = 3;
   * repeated TableGroup groups = 4;
   * optional string copayment = 5;
   * optional string authorizationNeeded = 6;
   * optional string value = 7
   * ;
   * optional string limit = 8;
   */
  tiers: EnrollmentPlan_CoverageElement_CoverageTier[];
}

export interface EnrollmentPlan_CoverageElement_CoverageTier {
  name?: string | undefined;
  order?: string | undefined;
  costs: EnrollmentPlan_CoverageElement_CoverageTierCosts[];
}

export interface EnrollmentPlan_CoverageElement_CoverageTierCosts {
  name?: string | undefined;
  iconClass?: string | undefined;
  value1M?: string | undefined;
  value2M?: string | undefined;
  value3M?: string | undefined;
}

export interface EnrollmentApplicantInfo {
  id: string;
  alternateFormat?: string | undefined;
  firstName?: string | undefined;
  lastName?:
    | string
    | undefined;
  /** optional Address address = 5; */
  address?: { [key: string]: any } | undefined;
  race?: string | undefined;
  phone?: string | undefined;
  mailingAddressDifferent?: string | undefined;
  language?: string | undefined;
}

export interface EnrollmentMedicareInfo {
  id: string;
  currentlyNursingHome?: string | undefined;
  enrolledMedicaidAssistanceProgram?: string | undefined;
  enrollmentPeriod?: string | undefined;
  proposedEffectiveDate?: string | undefined;
  medicareNumber?: string | undefined;
  planAEffectiveDate?: string | undefined;
  planBEffectiveDate?: string | undefined;
  otherPrescriptionDrugBecomesEffective?: string | undefined;
  workOnceEnrolled?: string | undefined;
  otherMedicalInsurance?: string | undefined;
}

export interface EnrollmentPlanInfo {
  id: string;
  planDetailsConfirmed?: boolean | undefined;
  isNotMedicareSupplementPlan?: boolean | undefined;
  doctorSpecifyNow?: boolean | undefined;
  doctors: Doctor[];
  isCurrentlyPatient?: boolean | undefined;
  paymentOption?: string | undefined;
}

export interface EnrollmentReviewSubmit {
  id: string;
  signatureType: EnrollmentSignatureType;
}

export interface EnrollmentCompletedSteps {
  prerequisites?: boolean | undefined;
  applicantInfo?: boolean | undefined;
  medicareInfo?: boolean | undefined;
  planInfo?: boolean | undefined;
  reviewSubmit?: boolean | undefined;
}

function createBaseEnrollmentCompat(): EnrollmentCompat {
  return {
    id: "",
    consumerId: "",
    plan: undefined,
    applicantInfo: undefined,
    medicareInfo: undefined,
    planInfo: undefined,
    reviewSubmit: undefined,
    completed: undefined,
    benefitsConfirmed: undefined,
    signatureOption: undefined,
    tpmoId: undefined,
    leadId: undefined,
  };
}

export const EnrollmentCompat = {
  encode(message: EnrollmentCompat, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.consumerId !== "") {
      writer.uint32(18).string(message.consumerId);
    }
    if (message.plan !== undefined) {
      EnrollmentPlan.encode(message.plan, writer.uint32(26).fork()).ldelim();
    }
    if (message.applicantInfo !== undefined) {
      EnrollmentApplicantInfo.encode(message.applicantInfo, writer.uint32(34).fork()).ldelim();
    }
    if (message.medicareInfo !== undefined) {
      EnrollmentMedicareInfo.encode(message.medicareInfo, writer.uint32(42).fork()).ldelim();
    }
    if (message.planInfo !== undefined) {
      EnrollmentPlanInfo.encode(message.planInfo, writer.uint32(50).fork()).ldelim();
    }
    if (message.reviewSubmit !== undefined) {
      EnrollmentReviewSubmit.encode(message.reviewSubmit, writer.uint32(58).fork()).ldelim();
    }
    if (message.completed !== undefined) {
      EnrollmentCompletedSteps.encode(message.completed, writer.uint32(66).fork()).ldelim();
    }
    if (message.benefitsConfirmed !== undefined) {
      writer.uint32(72).bool(message.benefitsConfirmed);
    }
    if (message.signatureOption !== undefined) {
      writer.uint32(82).string(message.signatureOption);
    }
    if (message.tpmoId !== undefined) {
      writer.uint32(90).string(message.tpmoId);
    }
    if (message.leadId !== undefined) {
      writer.uint32(98).string(message.leadId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentCompat {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentCompat();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.consumerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.plan = EnrollmentPlan.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.applicantInfo = EnrollmentApplicantInfo.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.medicareInfo = EnrollmentMedicareInfo.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.planInfo = EnrollmentPlanInfo.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.reviewSubmit = EnrollmentReviewSubmit.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.completed = EnrollmentCompletedSteps.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.benefitsConfirmed = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.signatureOption = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.tpmoId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.leadId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentCompat {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      consumerId: isSet(object.consumerId) ? String(object.consumerId) : "",
      plan: isSet(object.plan) ? EnrollmentPlan.fromJSON(object.plan) : undefined,
      applicantInfo: isSet(object.applicantInfo) ? EnrollmentApplicantInfo.fromJSON(object.applicantInfo) : undefined,
      medicareInfo: isSet(object.medicareInfo) ? EnrollmentMedicareInfo.fromJSON(object.medicareInfo) : undefined,
      planInfo: isSet(object.planInfo) ? EnrollmentPlanInfo.fromJSON(object.planInfo) : undefined,
      reviewSubmit: isSet(object.reviewSubmit) ? EnrollmentReviewSubmit.fromJSON(object.reviewSubmit) : undefined,
      completed: isSet(object.completed) ? EnrollmentCompletedSteps.fromJSON(object.completed) : undefined,
      benefitsConfirmed: isSet(object.benefitsConfirmed) ? Boolean(object.benefitsConfirmed) : undefined,
      signatureOption: isSet(object.signatureOption) ? String(object.signatureOption) : undefined,
      tpmoId: isSet(object.tpmoId) ? String(object.tpmoId) : undefined,
      leadId: isSet(object.leadId) ? String(object.leadId) : undefined,
    };
  },

  toJSON(message: EnrollmentCompat): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.consumerId !== undefined && (obj.consumerId = message.consumerId);
    message.plan !== undefined && (obj.plan = message.plan ? EnrollmentPlan.toJSON(message.plan) : undefined);
    message.applicantInfo !== undefined &&
      (obj.applicantInfo = message.applicantInfo ? EnrollmentApplicantInfo.toJSON(message.applicantInfo) : undefined);
    message.medicareInfo !== undefined &&
      (obj.medicareInfo = message.medicareInfo ? EnrollmentMedicareInfo.toJSON(message.medicareInfo) : undefined);
    message.planInfo !== undefined &&
      (obj.planInfo = message.planInfo ? EnrollmentPlanInfo.toJSON(message.planInfo) : undefined);
    message.reviewSubmit !== undefined &&
      (obj.reviewSubmit = message.reviewSubmit ? EnrollmentReviewSubmit.toJSON(message.reviewSubmit) : undefined);
    message.completed !== undefined &&
      (obj.completed = message.completed ? EnrollmentCompletedSteps.toJSON(message.completed) : undefined);
    message.benefitsConfirmed !== undefined && (obj.benefitsConfirmed = message.benefitsConfirmed);
    message.signatureOption !== undefined && (obj.signatureOption = message.signatureOption);
    message.tpmoId !== undefined && (obj.tpmoId = message.tpmoId);
    message.leadId !== undefined && (obj.leadId = message.leadId);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentCompat>, I>>(base?: I): EnrollmentCompat {
    return EnrollmentCompat.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentCompat>, I>>(object: I): EnrollmentCompat {
    const message = createBaseEnrollmentCompat();
    message.id = object.id ?? "";
    message.consumerId = object.consumerId ?? "";
    message.plan = (object.plan !== undefined && object.plan !== null)
      ? EnrollmentPlan.fromPartial(object.plan)
      : undefined;
    message.applicantInfo = (object.applicantInfo !== undefined && object.applicantInfo !== null)
      ? EnrollmentApplicantInfo.fromPartial(object.applicantInfo)
      : undefined;
    message.medicareInfo = (object.medicareInfo !== undefined && object.medicareInfo !== null)
      ? EnrollmentMedicareInfo.fromPartial(object.medicareInfo)
      : undefined;
    message.planInfo = (object.planInfo !== undefined && object.planInfo !== null)
      ? EnrollmentPlanInfo.fromPartial(object.planInfo)
      : undefined;
    message.reviewSubmit = (object.reviewSubmit !== undefined && object.reviewSubmit !== null)
      ? EnrollmentReviewSubmit.fromPartial(object.reviewSubmit)
      : undefined;
    message.completed = (object.completed !== undefined && object.completed !== null)
      ? EnrollmentCompletedSteps.fromPartial(object.completed)
      : undefined;
    message.benefitsConfirmed = object.benefitsConfirmed ?? undefined;
    message.signatureOption = object.signatureOption ?? undefined;
    message.tpmoId = object.tpmoId ?? undefined;
    message.leadId = object.leadId ?? undefined;
    return message;
  },
};

function createBaseEnrollmentPlan(): EnrollmentPlan {
  return {
    id: "",
    planBenefit: {},
    summary: [],
    inpatientCare: [],
    outpatientCare: [],
    coveragePhase: [],
    additional: [],
  };
}

export const EnrollmentPlan = {
  encode(message: EnrollmentPlan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    Object.entries(message.planBenefit).forEach(([key, value]) => {
      EnrollmentPlan_PlanBenefitEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    for (const v of message.summary) {
      EnrollmentPlan_SummaryElement.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.inpatientCare) {
      EnrollmentPlan_TableElement.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.outpatientCare) {
      EnrollmentPlan_TableElement.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.coveragePhase) {
      EnrollmentPlan_CoverageElement.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.additional) {
      EnrollmentPlan_TableElement.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = EnrollmentPlan_PlanBenefitEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.planBenefit[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.summary.push(EnrollmentPlan_SummaryElement.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.inpatientCare.push(EnrollmentPlan_TableElement.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.outpatientCare.push(EnrollmentPlan_TableElement.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.coveragePhase.push(EnrollmentPlan_CoverageElement.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.additional.push(EnrollmentPlan_TableElement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      planBenefit: isObject(object.planBenefit)
        ? Object.entries(object.planBenefit).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      summary: Array.isArray(object?.summary)
        ? object.summary.map((e: any) => EnrollmentPlan_SummaryElement.fromJSON(e))
        : [],
      inpatientCare: Array.isArray(object?.inpatientCare)
        ? object.inpatientCare.map((e: any) => EnrollmentPlan_TableElement.fromJSON(e))
        : [],
      outpatientCare: Array.isArray(object?.outpatientCare)
        ? object.outpatientCare.map((e: any) => EnrollmentPlan_TableElement.fromJSON(e))
        : [],
      coveragePhase: Array.isArray(object?.coveragePhase)
        ? object.coveragePhase.map((e: any) => EnrollmentPlan_CoverageElement.fromJSON(e))
        : [],
      additional: Array.isArray(object?.additional)
        ? object.additional.map((e: any) => EnrollmentPlan_TableElement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EnrollmentPlan): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    obj.planBenefit = {};
    if (message.planBenefit) {
      Object.entries(message.planBenefit).forEach(([k, v]) => {
        obj.planBenefit[k] = v;
      });
    }
    if (message.summary) {
      obj.summary = message.summary.map((e) => e ? EnrollmentPlan_SummaryElement.toJSON(e) : undefined);
    } else {
      obj.summary = [];
    }
    if (message.inpatientCare) {
      obj.inpatientCare = message.inpatientCare.map((e) => e ? EnrollmentPlan_TableElement.toJSON(e) : undefined);
    } else {
      obj.inpatientCare = [];
    }
    if (message.outpatientCare) {
      obj.outpatientCare = message.outpatientCare.map((e) => e ? EnrollmentPlan_TableElement.toJSON(e) : undefined);
    } else {
      obj.outpatientCare = [];
    }
    if (message.coveragePhase) {
      obj.coveragePhase = message.coveragePhase.map((e) => e ? EnrollmentPlan_CoverageElement.toJSON(e) : undefined);
    } else {
      obj.coveragePhase = [];
    }
    if (message.additional) {
      obj.additional = message.additional.map((e) => e ? EnrollmentPlan_TableElement.toJSON(e) : undefined);
    } else {
      obj.additional = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan>, I>>(base?: I): EnrollmentPlan {
    return EnrollmentPlan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan>, I>>(object: I): EnrollmentPlan {
    const message = createBaseEnrollmentPlan();
    message.id = object.id ?? "";
    message.planBenefit = Object.entries(object.planBenefit ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.summary = object.summary?.map((e) => EnrollmentPlan_SummaryElement.fromPartial(e)) || [];
    message.inpatientCare = object.inpatientCare?.map((e) => EnrollmentPlan_TableElement.fromPartial(e)) || [];
    message.outpatientCare = object.outpatientCare?.map((e) => EnrollmentPlan_TableElement.fromPartial(e)) || [];
    message.coveragePhase = object.coveragePhase?.map((e) => EnrollmentPlan_CoverageElement.fromPartial(e)) || [];
    message.additional = object.additional?.map((e) => EnrollmentPlan_TableElement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEnrollmentPlan_PlanBenefitEntry(): EnrollmentPlan_PlanBenefitEntry {
  return { key: "", value: "" };
}

export const EnrollmentPlan_PlanBenefitEntry = {
  encode(message: EnrollmentPlan_PlanBenefitEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_PlanBenefitEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_PlanBenefitEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_PlanBenefitEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: EnrollmentPlan_PlanBenefitEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefitEntry>, I>>(base?: I): EnrollmentPlan_PlanBenefitEntry {
    return EnrollmentPlan_PlanBenefitEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefitEntry>, I>>(
    object: I,
  ): EnrollmentPlan_PlanBenefitEntry {
    const message = createBaseEnrollmentPlan_PlanBenefitEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseEnrollmentPlan_PlanBenefit(): EnrollmentPlan_PlanBenefit {
  return {
    drugsCovered: undefined,
    mpfTemplateContact: [],
    contractId: undefined,
    planName: undefined,
    type: undefined,
    benefitCostPackages: [],
    doctorChoice: undefined,
    url: undefined,
    vbid: [],
    planTypeDesc: undefined,
    premium: undefined,
    costShares: [],
    planTotalPremiumNetRebate: undefined,
    categories: [],
    sntCategories: [],
  };
}

export const EnrollmentPlan_PlanBenefit = {
  encode(message: EnrollmentPlan_PlanBenefit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.drugsCovered !== undefined) {
      writer.uint32(10).string(message.drugsCovered);
    }
    for (const v of message.mpfTemplateContact) {
      EnrollmentPlan_PlanBenefit_MpfTemplateContact.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.contractId !== undefined) {
      writer.uint32(26).string(message.contractId);
    }
    if (message.planName !== undefined) {
      writer.uint32(34).string(message.planName);
    }
    if (message.type !== undefined) {
      writer.uint32(42).string(message.type);
    }
    for (const v of message.benefitCostPackages) {
      EnrollmentPlan_PlanBenefit_BenefitCostPackage.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.doctorChoice !== undefined) {
      writer.uint32(58).string(message.doctorChoice);
    }
    if (message.url !== undefined) {
      writer.uint32(66).string(message.url);
    }
    for (const v of message.vbid) {
      EnrollmentPlan_PlanBenefit_VBID.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.planTypeDesc !== undefined) {
      writer.uint32(82).string(message.planTypeDesc);
    }
    if (message.premium !== undefined) {
      writer.uint32(90).string(message.premium);
    }
    for (const v of message.costShares) {
      EnrollmentPlan_PlanBenefit_CostShare.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.planTotalPremiumNetRebate !== undefined) {
      writer.uint32(106).string(message.planTotalPremiumNetRebate);
    }
    for (const v of message.categories) {
      EnrollmentPlan_PlanBenefit_PlanCategory.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.sntCategories) {
      EnrollmentPlan_PlanBenefit_SntCategory.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_PlanBenefit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_PlanBenefit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.drugsCovered = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mpfTemplateContact.push(
            EnrollmentPlan_PlanBenefit_MpfTemplateContact.decode(reader, reader.uint32()),
          );
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contractId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.planName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.type = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.benefitCostPackages.push(
            EnrollmentPlan_PlanBenefit_BenefitCostPackage.decode(reader, reader.uint32()),
          );
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.doctorChoice = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.url = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.vbid.push(EnrollmentPlan_PlanBenefit_VBID.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.planTypeDesc = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.premium = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.costShares.push(EnrollmentPlan_PlanBenefit_CostShare.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.planTotalPremiumNetRebate = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.categories.push(EnrollmentPlan_PlanBenefit_PlanCategory.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.sntCategories.push(EnrollmentPlan_PlanBenefit_SntCategory.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_PlanBenefit {
    return {
      drugsCovered: isSet(object.drugsCovered) ? String(object.drugsCovered) : undefined,
      mpfTemplateContact: Array.isArray(object?.mpfTemplateContact)
        ? object.mpfTemplateContact.map((e: any) => EnrollmentPlan_PlanBenefit_MpfTemplateContact.fromJSON(e))
        : [],
      contractId: isSet(object.contractId) ? String(object.contractId) : undefined,
      planName: isSet(object.planName) ? String(object.planName) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      benefitCostPackages: Array.isArray(object?.benefitCostPackages)
        ? object.benefitCostPackages.map((e: any) => EnrollmentPlan_PlanBenefit_BenefitCostPackage.fromJSON(e))
        : [],
      doctorChoice: isSet(object.doctorChoice) ? String(object.doctorChoice) : undefined,
      url: isSet(object.url) ? String(object.url) : undefined,
      vbid: Array.isArray(object?.vbid) ? object.vbid.map((e: any) => EnrollmentPlan_PlanBenefit_VBID.fromJSON(e)) : [],
      planTypeDesc: isSet(object.planTypeDesc) ? String(object.planTypeDesc) : undefined,
      premium: isSet(object.premium) ? String(object.premium) : undefined,
      costShares: Array.isArray(object?.costShares)
        ? object.costShares.map((e: any) => EnrollmentPlan_PlanBenefit_CostShare.fromJSON(e))
        : [],
      planTotalPremiumNetRebate: isSet(object.planTotalPremiumNetRebate)
        ? String(object.planTotalPremiumNetRebate)
        : undefined,
      categories: Array.isArray(object?.categories)
        ? object.categories.map((e: any) => EnrollmentPlan_PlanBenefit_PlanCategory.fromJSON(e))
        : [],
      sntCategories: Array.isArray(object?.sntCategories)
        ? object.sntCategories.map((e: any) => EnrollmentPlan_PlanBenefit_SntCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EnrollmentPlan_PlanBenefit): unknown {
    const obj: any = {};
    message.drugsCovered !== undefined && (obj.drugsCovered = message.drugsCovered);
    if (message.mpfTemplateContact) {
      obj.mpfTemplateContact = message.mpfTemplateContact.map((e) =>
        e ? EnrollmentPlan_PlanBenefit_MpfTemplateContact.toJSON(e) : undefined
      );
    } else {
      obj.mpfTemplateContact = [];
    }
    message.contractId !== undefined && (obj.contractId = message.contractId);
    message.planName !== undefined && (obj.planName = message.planName);
    message.type !== undefined && (obj.type = message.type);
    if (message.benefitCostPackages) {
      obj.benefitCostPackages = message.benefitCostPackages.map((e) =>
        e ? EnrollmentPlan_PlanBenefit_BenefitCostPackage.toJSON(e) : undefined
      );
    } else {
      obj.benefitCostPackages = [];
    }
    message.doctorChoice !== undefined && (obj.doctorChoice = message.doctorChoice);
    message.url !== undefined && (obj.url = message.url);
    if (message.vbid) {
      obj.vbid = message.vbid.map((e) => e ? EnrollmentPlan_PlanBenefit_VBID.toJSON(e) : undefined);
    } else {
      obj.vbid = [];
    }
    message.planTypeDesc !== undefined && (obj.planTypeDesc = message.planTypeDesc);
    message.premium !== undefined && (obj.premium = message.premium);
    if (message.costShares) {
      obj.costShares = message.costShares.map((e) => e ? EnrollmentPlan_PlanBenefit_CostShare.toJSON(e) : undefined);
    } else {
      obj.costShares = [];
    }
    message.planTotalPremiumNetRebate !== undefined &&
      (obj.planTotalPremiumNetRebate = message.planTotalPremiumNetRebate);
    if (message.categories) {
      obj.categories = message.categories.map((e) => e ? EnrollmentPlan_PlanBenefit_PlanCategory.toJSON(e) : undefined);
    } else {
      obj.categories = [];
    }
    if (message.sntCategories) {
      obj.sntCategories = message.sntCategories.map((e) =>
        e ? EnrollmentPlan_PlanBenefit_SntCategory.toJSON(e) : undefined
      );
    } else {
      obj.sntCategories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit>, I>>(base?: I): EnrollmentPlan_PlanBenefit {
    return EnrollmentPlan_PlanBenefit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit>, I>>(object: I): EnrollmentPlan_PlanBenefit {
    const message = createBaseEnrollmentPlan_PlanBenefit();
    message.drugsCovered = object.drugsCovered ?? undefined;
    message.mpfTemplateContact =
      object.mpfTemplateContact?.map((e) => EnrollmentPlan_PlanBenefit_MpfTemplateContact.fromPartial(e)) || [];
    message.contractId = object.contractId ?? undefined;
    message.planName = object.planName ?? undefined;
    message.type = object.type ?? undefined;
    message.benefitCostPackages =
      object.benefitCostPackages?.map((e) => EnrollmentPlan_PlanBenefit_BenefitCostPackage.fromPartial(e)) || [];
    message.doctorChoice = object.doctorChoice ?? undefined;
    message.url = object.url ?? undefined;
    message.vbid = object.vbid?.map((e) => EnrollmentPlan_PlanBenefit_VBID.fromPartial(e)) || [];
    message.planTypeDesc = object.planTypeDesc ?? undefined;
    message.premium = object.premium ?? undefined;
    message.costShares = object.costShares?.map((e) => EnrollmentPlan_PlanBenefit_CostShare.fromPartial(e)) || [];
    message.planTotalPremiumNetRebate = object.planTotalPremiumNetRebate ?? undefined;
    message.categories = object.categories?.map((e) => EnrollmentPlan_PlanBenefit_PlanCategory.fromPartial(e)) || [];
    message.sntCategories = object.sntCategories?.map((e) => EnrollmentPlan_PlanBenefit_SntCategory.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseEnrollmentPlan_PlanBenefit_MpfTemplateContact(): EnrollmentPlan_PlanBenefit_MpfTemplateContact {
  return {};
}

export const EnrollmentPlan_PlanBenefit_MpfTemplateContact = {
  encode(_: EnrollmentPlan_PlanBenefit_MpfTemplateContact, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_PlanBenefit_MpfTemplateContact {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_PlanBenefit_MpfTemplateContact();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EnrollmentPlan_PlanBenefit_MpfTemplateContact {
    return {};
  },

  toJSON(_: EnrollmentPlan_PlanBenefit_MpfTemplateContact): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_MpfTemplateContact>, I>>(
    base?: I,
  ): EnrollmentPlan_PlanBenefit_MpfTemplateContact {
    return EnrollmentPlan_PlanBenefit_MpfTemplateContact.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_MpfTemplateContact>, I>>(
    _: I,
  ): EnrollmentPlan_PlanBenefit_MpfTemplateContact {
    const message = createBaseEnrollmentPlan_PlanBenefit_MpfTemplateContact();
    return message;
  },
};

function createBaseEnrollmentPlan_PlanBenefit_BenefitCostPackage(): EnrollmentPlan_PlanBenefit_BenefitCostPackage {
  return {};
}

export const EnrollmentPlan_PlanBenefit_BenefitCostPackage = {
  encode(_: EnrollmentPlan_PlanBenefit_BenefitCostPackage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_PlanBenefit_BenefitCostPackage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_PlanBenefit_BenefitCostPackage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EnrollmentPlan_PlanBenefit_BenefitCostPackage {
    return {};
  },

  toJSON(_: EnrollmentPlan_PlanBenefit_BenefitCostPackage): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_BenefitCostPackage>, I>>(
    base?: I,
  ): EnrollmentPlan_PlanBenefit_BenefitCostPackage {
    return EnrollmentPlan_PlanBenefit_BenefitCostPackage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_BenefitCostPackage>, I>>(
    _: I,
  ): EnrollmentPlan_PlanBenefit_BenefitCostPackage {
    const message = createBaseEnrollmentPlan_PlanBenefit_BenefitCostPackage();
    return message;
  },
};

function createBaseEnrollmentPlan_PlanBenefit_VBID(): EnrollmentPlan_PlanBenefit_VBID {
  return {};
}

export const EnrollmentPlan_PlanBenefit_VBID = {
  encode(_: EnrollmentPlan_PlanBenefit_VBID, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_PlanBenefit_VBID {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_PlanBenefit_VBID();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EnrollmentPlan_PlanBenefit_VBID {
    return {};
  },

  toJSON(_: EnrollmentPlan_PlanBenefit_VBID): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_VBID>, I>>(base?: I): EnrollmentPlan_PlanBenefit_VBID {
    return EnrollmentPlan_PlanBenefit_VBID.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_VBID>, I>>(_: I): EnrollmentPlan_PlanBenefit_VBID {
    const message = createBaseEnrollmentPlan_PlanBenefit_VBID();
    return message;
  },
};

function createBaseEnrollmentPlan_PlanBenefit_CostShare(): EnrollmentPlan_PlanBenefit_CostShare {
  return {};
}

export const EnrollmentPlan_PlanBenefit_CostShare = {
  encode(_: EnrollmentPlan_PlanBenefit_CostShare, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_PlanBenefit_CostShare {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_PlanBenefit_CostShare();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EnrollmentPlan_PlanBenefit_CostShare {
    return {};
  },

  toJSON(_: EnrollmentPlan_PlanBenefit_CostShare): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_CostShare>, I>>(
    base?: I,
  ): EnrollmentPlan_PlanBenefit_CostShare {
    return EnrollmentPlan_PlanBenefit_CostShare.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_CostShare>, I>>(
    _: I,
  ): EnrollmentPlan_PlanBenefit_CostShare {
    const message = createBaseEnrollmentPlan_PlanBenefit_CostShare();
    return message;
  },
};

function createBaseEnrollmentPlan_PlanBenefit_PlanCategory(): EnrollmentPlan_PlanBenefit_PlanCategory {
  return {};
}

export const EnrollmentPlan_PlanBenefit_PlanCategory = {
  encode(_: EnrollmentPlan_PlanBenefit_PlanCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_PlanBenefit_PlanCategory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_PlanBenefit_PlanCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EnrollmentPlan_PlanBenefit_PlanCategory {
    return {};
  },

  toJSON(_: EnrollmentPlan_PlanBenefit_PlanCategory): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_PlanCategory>, I>>(
    base?: I,
  ): EnrollmentPlan_PlanBenefit_PlanCategory {
    return EnrollmentPlan_PlanBenefit_PlanCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_PlanCategory>, I>>(
    _: I,
  ): EnrollmentPlan_PlanBenefit_PlanCategory {
    const message = createBaseEnrollmentPlan_PlanBenefit_PlanCategory();
    return message;
  },
};

function createBaseEnrollmentPlan_PlanBenefit_SntCategory(): EnrollmentPlan_PlanBenefit_SntCategory {
  return {};
}

export const EnrollmentPlan_PlanBenefit_SntCategory = {
  encode(_: EnrollmentPlan_PlanBenefit_SntCategory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_PlanBenefit_SntCategory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_PlanBenefit_SntCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): EnrollmentPlan_PlanBenefit_SntCategory {
    return {};
  },

  toJSON(_: EnrollmentPlan_PlanBenefit_SntCategory): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_SntCategory>, I>>(
    base?: I,
  ): EnrollmentPlan_PlanBenefit_SntCategory {
    return EnrollmentPlan_PlanBenefit_SntCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_PlanBenefit_SntCategory>, I>>(
    _: I,
  ): EnrollmentPlan_PlanBenefit_SntCategory {
    const message = createBaseEnrollmentPlan_PlanBenefit_SntCategory();
    return message;
  },
};

function createBaseEnrollmentPlan_SummaryElement(): EnrollmentPlan_SummaryElement {
  return { name: undefined, iconClass: undefined, value: undefined };
}

export const EnrollmentPlan_SummaryElement = {
  encode(message: EnrollmentPlan_SummaryElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    if (message.value !== undefined) {
      writer.uint32(26).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_SummaryElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_SummaryElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_SummaryElement {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      value: isSet(object.value) ? String(object.value) : undefined,
    };
  },

  toJSON(message: EnrollmentPlan_SummaryElement): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_SummaryElement>, I>>(base?: I): EnrollmentPlan_SummaryElement {
    return EnrollmentPlan_SummaryElement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_SummaryElement>, I>>(
    object: I,
  ): EnrollmentPlan_SummaryElement {
    const message = createBaseEnrollmentPlan_SummaryElement();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseEnrollmentPlan_TableElement(): EnrollmentPlan_TableElement {
  return {
    name: undefined,
    iconClass: undefined,
    services: [],
    groups: [],
    copayment: undefined,
    authorizationNeeded: undefined,
    value: undefined,
    limit: undefined,
  };
}

export const EnrollmentPlan_TableElement = {
  encode(message: EnrollmentPlan_TableElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    for (const v of message.services) {
      EnrollmentPlan_TableElement_TableService.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.groups) {
      EnrollmentPlan_TableElement_TableGroup.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.copayment !== undefined) {
      writer.uint32(42).string(message.copayment);
    }
    if (message.authorizationNeeded !== undefined) {
      writer.uint32(50).string(message.authorizationNeeded);
    }
    if (message.value !== undefined) {
      writer.uint32(58).string(message.value);
    }
    if (message.limit !== undefined) {
      writer.uint32(66).string(message.limit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_TableElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_TableElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.services.push(EnrollmentPlan_TableElement_TableService.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.groups.push(EnrollmentPlan_TableElement_TableGroup.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.copayment = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.authorizationNeeded = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.value = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.limit = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_TableElement {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      services: Array.isArray(object?.services)
        ? object.services.map((e: any) => EnrollmentPlan_TableElement_TableService.fromJSON(e))
        : [],
      groups: Array.isArray(object?.groups)
        ? object.groups.map((e: any) => EnrollmentPlan_TableElement_TableGroup.fromJSON(e))
        : [],
      copayment: isSet(object.copayment) ? String(object.copayment) : undefined,
      authorizationNeeded: isSet(object.authorizationNeeded) ? String(object.authorizationNeeded) : undefined,
      value: isSet(object.value) ? String(object.value) : undefined,
      limit: isSet(object.limit) ? String(object.limit) : undefined,
    };
  },

  toJSON(message: EnrollmentPlan_TableElement): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    if (message.services) {
      obj.services = message.services.map((e) => e ? EnrollmentPlan_TableElement_TableService.toJSON(e) : undefined);
    } else {
      obj.services = [];
    }
    if (message.groups) {
      obj.groups = message.groups.map((e) => e ? EnrollmentPlan_TableElement_TableGroup.toJSON(e) : undefined);
    } else {
      obj.groups = [];
    }
    message.copayment !== undefined && (obj.copayment = message.copayment);
    message.authorizationNeeded !== undefined && (obj.authorizationNeeded = message.authorizationNeeded);
    message.value !== undefined && (obj.value = message.value);
    message.limit !== undefined && (obj.limit = message.limit);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_TableElement>, I>>(base?: I): EnrollmentPlan_TableElement {
    return EnrollmentPlan_TableElement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_TableElement>, I>>(object: I): EnrollmentPlan_TableElement {
    const message = createBaseEnrollmentPlan_TableElement();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.services = object.services?.map((e) => EnrollmentPlan_TableElement_TableService.fromPartial(e)) || [];
    message.groups = object.groups?.map((e) => EnrollmentPlan_TableElement_TableGroup.fromPartial(e)) || [];
    message.copayment = object.copayment ?? undefined;
    message.authorizationNeeded = object.authorizationNeeded ?? undefined;
    message.value = object.value ?? undefined;
    message.limit = object.limit ?? undefined;
    return message;
  },
};

function createBaseEnrollmentPlan_TableElement_TableGroup(): EnrollmentPlan_TableElement_TableGroup {
  return {
    name: undefined,
    iconClass: undefined,
    services: [],
    value: undefined,
    authorizationNeeded: undefined,
    limit: undefined,
  };
}

export const EnrollmentPlan_TableElement_TableGroup = {
  encode(message: EnrollmentPlan_TableElement_TableGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    for (const v of message.services) {
      EnrollmentPlan_TableElement_TableService.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.value !== undefined) {
      writer.uint32(34).string(message.value);
    }
    if (message.authorizationNeeded !== undefined) {
      writer.uint32(42).string(message.authorizationNeeded);
    }
    if (message.limit !== undefined) {
      writer.uint32(50).string(message.limit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_TableElement_TableGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_TableElement_TableGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.services.push(EnrollmentPlan_TableElement_TableService.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.authorizationNeeded = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.limit = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_TableElement_TableGroup {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      services: Array.isArray(object?.services)
        ? object.services.map((e: any) => EnrollmentPlan_TableElement_TableService.fromJSON(e))
        : [],
      value: isSet(object.value) ? String(object.value) : undefined,
      authorizationNeeded: isSet(object.authorizationNeeded) ? String(object.authorizationNeeded) : undefined,
      limit: isSet(object.limit) ? String(object.limit) : undefined,
    };
  },

  toJSON(message: EnrollmentPlan_TableElement_TableGroup): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    if (message.services) {
      obj.services = message.services.map((e) => e ? EnrollmentPlan_TableElement_TableService.toJSON(e) : undefined);
    } else {
      obj.services = [];
    }
    message.value !== undefined && (obj.value = message.value);
    message.authorizationNeeded !== undefined && (obj.authorizationNeeded = message.authorizationNeeded);
    message.limit !== undefined && (obj.limit = message.limit);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_TableElement_TableGroup>, I>>(
    base?: I,
  ): EnrollmentPlan_TableElement_TableGroup {
    return EnrollmentPlan_TableElement_TableGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_TableElement_TableGroup>, I>>(
    object: I,
  ): EnrollmentPlan_TableElement_TableGroup {
    const message = createBaseEnrollmentPlan_TableElement_TableGroup();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.services = object.services?.map((e) => EnrollmentPlan_TableElement_TableService.fromPartial(e)) || [];
    message.value = object.value ?? undefined;
    message.authorizationNeeded = object.authorizationNeeded ?? undefined;
    message.limit = object.limit ?? undefined;
    return message;
  },
};

function createBaseEnrollmentPlan_TableElement_TableService(): EnrollmentPlan_TableElement_TableService {
  return { name: undefined, value: undefined, authorizationNeeded: undefined, limit: undefined, network: undefined };
}

export const EnrollmentPlan_TableElement_TableService = {
  encode(message: EnrollmentPlan_TableElement_TableService, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== undefined) {
      writer.uint32(18).string(message.value);
    }
    if (message.authorizationNeeded !== undefined) {
      writer.uint32(26).string(message.authorizationNeeded);
    }
    if (message.limit !== undefined) {
      writer.uint32(34).string(message.limit);
    }
    if (message.network !== undefined) {
      writer.uint32(42).string(message.network);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_TableElement_TableService {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_TableElement_TableService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.authorizationNeeded = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.limit = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.network = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_TableElement_TableService {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      value: isSet(object.value) ? String(object.value) : undefined,
      authorizationNeeded: isSet(object.authorizationNeeded) ? String(object.authorizationNeeded) : undefined,
      limit: isSet(object.limit) ? String(object.limit) : undefined,
      network: isSet(object.network) ? String(object.network) : undefined,
    };
  },

  toJSON(message: EnrollmentPlan_TableElement_TableService): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    message.authorizationNeeded !== undefined && (obj.authorizationNeeded = message.authorizationNeeded);
    message.limit !== undefined && (obj.limit = message.limit);
    message.network !== undefined && (obj.network = message.network);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_TableElement_TableService>, I>>(
    base?: I,
  ): EnrollmentPlan_TableElement_TableService {
    return EnrollmentPlan_TableElement_TableService.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_TableElement_TableService>, I>>(
    object: I,
  ): EnrollmentPlan_TableElement_TableService {
    const message = createBaseEnrollmentPlan_TableElement_TableService();
    message.name = object.name ?? undefined;
    message.value = object.value ?? undefined;
    message.authorizationNeeded = object.authorizationNeeded ?? undefined;
    message.limit = object.limit ?? undefined;
    message.network = object.network ?? undefined;
    return message;
  },
};

function createBaseEnrollmentPlan_CoverageElement(): EnrollmentPlan_CoverageElement {
  return { name: undefined, iconClass: undefined, order: undefined, tiers: [] };
}

export const EnrollmentPlan_CoverageElement = {
  encode(message: EnrollmentPlan_CoverageElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    if (message.order !== undefined) {
      writer.uint32(26).string(message.order);
    }
    for (const v of message.tiers) {
      EnrollmentPlan_CoverageElement_CoverageTier.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_CoverageElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_CoverageElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.order = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tiers.push(EnrollmentPlan_CoverageElement_CoverageTier.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_CoverageElement {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      order: isSet(object.order) ? String(object.order) : undefined,
      tiers: Array.isArray(object?.tiers)
        ? object.tiers.map((e: any) => EnrollmentPlan_CoverageElement_CoverageTier.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EnrollmentPlan_CoverageElement): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    message.order !== undefined && (obj.order = message.order);
    if (message.tiers) {
      obj.tiers = message.tiers.map((e) => e ? EnrollmentPlan_CoverageElement_CoverageTier.toJSON(e) : undefined);
    } else {
      obj.tiers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_CoverageElement>, I>>(base?: I): EnrollmentPlan_CoverageElement {
    return EnrollmentPlan_CoverageElement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_CoverageElement>, I>>(
    object: I,
  ): EnrollmentPlan_CoverageElement {
    const message = createBaseEnrollmentPlan_CoverageElement();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.order = object.order ?? undefined;
    message.tiers = object.tiers?.map((e) => EnrollmentPlan_CoverageElement_CoverageTier.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEnrollmentPlan_CoverageElement_CoverageTier(): EnrollmentPlan_CoverageElement_CoverageTier {
  return { name: undefined, order: undefined, costs: [] };
}

export const EnrollmentPlan_CoverageElement_CoverageTier = {
  encode(message: EnrollmentPlan_CoverageElement_CoverageTier, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.order !== undefined) {
      writer.uint32(18).string(message.order);
    }
    for (const v of message.costs) {
      EnrollmentPlan_CoverageElement_CoverageTierCosts.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_CoverageElement_CoverageTier {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_CoverageElement_CoverageTier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.order = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.costs.push(EnrollmentPlan_CoverageElement_CoverageTierCosts.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_CoverageElement_CoverageTier {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      order: isSet(object.order) ? String(object.order) : undefined,
      costs: Array.isArray(object?.costs)
        ? object.costs.map((e: any) => EnrollmentPlan_CoverageElement_CoverageTierCosts.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EnrollmentPlan_CoverageElement_CoverageTier): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.order !== undefined && (obj.order = message.order);
    if (message.costs) {
      obj.costs = message.costs.map((e) => e ? EnrollmentPlan_CoverageElement_CoverageTierCosts.toJSON(e) : undefined);
    } else {
      obj.costs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_CoverageElement_CoverageTier>, I>>(
    base?: I,
  ): EnrollmentPlan_CoverageElement_CoverageTier {
    return EnrollmentPlan_CoverageElement_CoverageTier.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_CoverageElement_CoverageTier>, I>>(
    object: I,
  ): EnrollmentPlan_CoverageElement_CoverageTier {
    const message = createBaseEnrollmentPlan_CoverageElement_CoverageTier();
    message.name = object.name ?? undefined;
    message.order = object.order ?? undefined;
    message.costs = object.costs?.map((e) => EnrollmentPlan_CoverageElement_CoverageTierCosts.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEnrollmentPlan_CoverageElement_CoverageTierCosts(): EnrollmentPlan_CoverageElement_CoverageTierCosts {
  return { name: undefined, iconClass: undefined, value1M: undefined, value2M: undefined, value3M: undefined };
}

export const EnrollmentPlan_CoverageElement_CoverageTierCosts = {
  encode(
    message: EnrollmentPlan_CoverageElement_CoverageTierCosts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    if (message.value1M !== undefined) {
      writer.uint32(26).string(message.value1M);
    }
    if (message.value2M !== undefined) {
      writer.uint32(34).string(message.value2M);
    }
    if (message.value3M !== undefined) {
      writer.uint32(42).string(message.value3M);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlan_CoverageElement_CoverageTierCosts {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlan_CoverageElement_CoverageTierCosts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value1M = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value2M = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.value3M = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlan_CoverageElement_CoverageTierCosts {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      value1M: isSet(object.value1M) ? String(object.value1M) : undefined,
      value2M: isSet(object.value2M) ? String(object.value2M) : undefined,
      value3M: isSet(object.value3M) ? String(object.value3M) : undefined,
    };
  },

  toJSON(message: EnrollmentPlan_CoverageElement_CoverageTierCosts): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    message.value1M !== undefined && (obj.value1M = message.value1M);
    message.value2M !== undefined && (obj.value2M = message.value2M);
    message.value3M !== undefined && (obj.value3M = message.value3M);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlan_CoverageElement_CoverageTierCosts>, I>>(
    base?: I,
  ): EnrollmentPlan_CoverageElement_CoverageTierCosts {
    return EnrollmentPlan_CoverageElement_CoverageTierCosts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlan_CoverageElement_CoverageTierCosts>, I>>(
    object: I,
  ): EnrollmentPlan_CoverageElement_CoverageTierCosts {
    const message = createBaseEnrollmentPlan_CoverageElement_CoverageTierCosts();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.value1M = object.value1M ?? undefined;
    message.value2M = object.value2M ?? undefined;
    message.value3M = object.value3M ?? undefined;
    return message;
  },
};

function createBaseEnrollmentApplicantInfo(): EnrollmentApplicantInfo {
  return {
    id: "",
    alternateFormat: undefined,
    firstName: undefined,
    lastName: undefined,
    address: undefined,
    race: undefined,
    phone: undefined,
    mailingAddressDifferent: undefined,
    language: undefined,
  };
}

export const EnrollmentApplicantInfo = {
  encode(message: EnrollmentApplicantInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.alternateFormat !== undefined) {
      writer.uint32(18).string(message.alternateFormat);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.address !== undefined) {
      Struct.encode(Struct.wrap(message.address), writer.uint32(42).fork()).ldelim();
    }
    if (message.race !== undefined) {
      writer.uint32(50).string(message.race);
    }
    if (message.phone !== undefined) {
      writer.uint32(58).string(message.phone);
    }
    if (message.mailingAddressDifferent !== undefined) {
      writer.uint32(66).string(message.mailingAddressDifferent);
    }
    if (message.language !== undefined) {
      writer.uint32(74).string(message.language);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentApplicantInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentApplicantInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alternateFormat = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.address = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.race = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.mailingAddressDifferent = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.language = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentApplicantInfo {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      alternateFormat: isSet(object.alternateFormat) ? String(object.alternateFormat) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      address: isObject(object.address) ? object.address : undefined,
      race: isSet(object.race) ? String(object.race) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      mailingAddressDifferent: isSet(object.mailingAddressDifferent)
        ? String(object.mailingAddressDifferent)
        : undefined,
      language: isSet(object.language) ? String(object.language) : undefined,
    };
  },

  toJSON(message: EnrollmentApplicantInfo): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.alternateFormat !== undefined && (obj.alternateFormat = message.alternateFormat);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.address !== undefined && (obj.address = message.address);
    message.race !== undefined && (obj.race = message.race);
    message.phone !== undefined && (obj.phone = message.phone);
    message.mailingAddressDifferent !== undefined && (obj.mailingAddressDifferent = message.mailingAddressDifferent);
    message.language !== undefined && (obj.language = message.language);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentApplicantInfo>, I>>(base?: I): EnrollmentApplicantInfo {
    return EnrollmentApplicantInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentApplicantInfo>, I>>(object: I): EnrollmentApplicantInfo {
    const message = createBaseEnrollmentApplicantInfo();
    message.id = object.id ?? "";
    message.alternateFormat = object.alternateFormat ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.address = object.address ?? undefined;
    message.race = object.race ?? undefined;
    message.phone = object.phone ?? undefined;
    message.mailingAddressDifferent = object.mailingAddressDifferent ?? undefined;
    message.language = object.language ?? undefined;
    return message;
  },
};

function createBaseEnrollmentMedicareInfo(): EnrollmentMedicareInfo {
  return {
    id: "",
    currentlyNursingHome: undefined,
    enrolledMedicaidAssistanceProgram: undefined,
    enrollmentPeriod: undefined,
    proposedEffectiveDate: undefined,
    medicareNumber: undefined,
    planAEffectiveDate: undefined,
    planBEffectiveDate: undefined,
    otherPrescriptionDrugBecomesEffective: undefined,
    workOnceEnrolled: undefined,
    otherMedicalInsurance: undefined,
  };
}

export const EnrollmentMedicareInfo = {
  encode(message: EnrollmentMedicareInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.currentlyNursingHome !== undefined) {
      writer.uint32(18).string(message.currentlyNursingHome);
    }
    if (message.enrolledMedicaidAssistanceProgram !== undefined) {
      writer.uint32(26).string(message.enrolledMedicaidAssistanceProgram);
    }
    if (message.enrollmentPeriod !== undefined) {
      writer.uint32(34).string(message.enrollmentPeriod);
    }
    if (message.proposedEffectiveDate !== undefined) {
      writer.uint32(42).string(message.proposedEffectiveDate);
    }
    if (message.medicareNumber !== undefined) {
      writer.uint32(50).string(message.medicareNumber);
    }
    if (message.planAEffectiveDate !== undefined) {
      writer.uint32(58).string(message.planAEffectiveDate);
    }
    if (message.planBEffectiveDate !== undefined) {
      writer.uint32(66).string(message.planBEffectiveDate);
    }
    if (message.otherPrescriptionDrugBecomesEffective !== undefined) {
      writer.uint32(74).string(message.otherPrescriptionDrugBecomesEffective);
    }
    if (message.workOnceEnrolled !== undefined) {
      writer.uint32(82).string(message.workOnceEnrolled);
    }
    if (message.otherMedicalInsurance !== undefined) {
      writer.uint32(90).string(message.otherMedicalInsurance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentMedicareInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentMedicareInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currentlyNursingHome = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.enrolledMedicaidAssistanceProgram = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.enrollmentPeriod = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.proposedEffectiveDate = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.medicareNumber = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.planAEffectiveDate = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.planBEffectiveDate = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.otherPrescriptionDrugBecomesEffective = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.workOnceEnrolled = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.otherMedicalInsurance = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentMedicareInfo {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      currentlyNursingHome: isSet(object.currentlyNursingHome) ? String(object.currentlyNursingHome) : undefined,
      enrolledMedicaidAssistanceProgram: isSet(object.enrolledMedicaidAssistanceProgram)
        ? String(object.enrolledMedicaidAssistanceProgram)
        : undefined,
      enrollmentPeriod: isSet(object.enrollmentPeriod) ? String(object.enrollmentPeriod) : undefined,
      proposedEffectiveDate: isSet(object.proposedEffectiveDate) ? String(object.proposedEffectiveDate) : undefined,
      medicareNumber: isSet(object.medicareNumber) ? String(object.medicareNumber) : undefined,
      planAEffectiveDate: isSet(object.planAEffectiveDate) ? String(object.planAEffectiveDate) : undefined,
      planBEffectiveDate: isSet(object.planBEffectiveDate) ? String(object.planBEffectiveDate) : undefined,
      otherPrescriptionDrugBecomesEffective: isSet(object.otherPrescriptionDrugBecomesEffective)
        ? String(object.otherPrescriptionDrugBecomesEffective)
        : undefined,
      workOnceEnrolled: isSet(object.workOnceEnrolled) ? String(object.workOnceEnrolled) : undefined,
      otherMedicalInsurance: isSet(object.otherMedicalInsurance) ? String(object.otherMedicalInsurance) : undefined,
    };
  },

  toJSON(message: EnrollmentMedicareInfo): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.currentlyNursingHome !== undefined && (obj.currentlyNursingHome = message.currentlyNursingHome);
    message.enrolledMedicaidAssistanceProgram !== undefined &&
      (obj.enrolledMedicaidAssistanceProgram = message.enrolledMedicaidAssistanceProgram);
    message.enrollmentPeriod !== undefined && (obj.enrollmentPeriod = message.enrollmentPeriod);
    message.proposedEffectiveDate !== undefined && (obj.proposedEffectiveDate = message.proposedEffectiveDate);
    message.medicareNumber !== undefined && (obj.medicareNumber = message.medicareNumber);
    message.planAEffectiveDate !== undefined && (obj.planAEffectiveDate = message.planAEffectiveDate);
    message.planBEffectiveDate !== undefined && (obj.planBEffectiveDate = message.planBEffectiveDate);
    message.otherPrescriptionDrugBecomesEffective !== undefined &&
      (obj.otherPrescriptionDrugBecomesEffective = message.otherPrescriptionDrugBecomesEffective);
    message.workOnceEnrolled !== undefined && (obj.workOnceEnrolled = message.workOnceEnrolled);
    message.otherMedicalInsurance !== undefined && (obj.otherMedicalInsurance = message.otherMedicalInsurance);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentMedicareInfo>, I>>(base?: I): EnrollmentMedicareInfo {
    return EnrollmentMedicareInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentMedicareInfo>, I>>(object: I): EnrollmentMedicareInfo {
    const message = createBaseEnrollmentMedicareInfo();
    message.id = object.id ?? "";
    message.currentlyNursingHome = object.currentlyNursingHome ?? undefined;
    message.enrolledMedicaidAssistanceProgram = object.enrolledMedicaidAssistanceProgram ?? undefined;
    message.enrollmentPeriod = object.enrollmentPeriod ?? undefined;
    message.proposedEffectiveDate = object.proposedEffectiveDate ?? undefined;
    message.medicareNumber = object.medicareNumber ?? undefined;
    message.planAEffectiveDate = object.planAEffectiveDate ?? undefined;
    message.planBEffectiveDate = object.planBEffectiveDate ?? undefined;
    message.otherPrescriptionDrugBecomesEffective = object.otherPrescriptionDrugBecomesEffective ?? undefined;
    message.workOnceEnrolled = object.workOnceEnrolled ?? undefined;
    message.otherMedicalInsurance = object.otherMedicalInsurance ?? undefined;
    return message;
  },
};

function createBaseEnrollmentPlanInfo(): EnrollmentPlanInfo {
  return {
    id: "",
    planDetailsConfirmed: undefined,
    isNotMedicareSupplementPlan: undefined,
    doctorSpecifyNow: undefined,
    doctors: [],
    isCurrentlyPatient: undefined,
    paymentOption: undefined,
  };
}

export const EnrollmentPlanInfo = {
  encode(message: EnrollmentPlanInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.planDetailsConfirmed !== undefined) {
      writer.uint32(16).bool(message.planDetailsConfirmed);
    }
    if (message.isNotMedicareSupplementPlan !== undefined) {
      writer.uint32(24).bool(message.isNotMedicareSupplementPlan);
    }
    if (message.doctorSpecifyNow !== undefined) {
      writer.uint32(32).bool(message.doctorSpecifyNow);
    }
    for (const v of message.doctors) {
      Doctor.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.isCurrentlyPatient !== undefined) {
      writer.uint32(48).bool(message.isCurrentlyPatient);
    }
    if (message.paymentOption !== undefined) {
      writer.uint32(58).string(message.paymentOption);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentPlanInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentPlanInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.planDetailsConfirmed = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isNotMedicareSupplementPlan = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.doctorSpecifyNow = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.doctors.push(Doctor.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isCurrentlyPatient = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.paymentOption = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentPlanInfo {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      planDetailsConfirmed: isSet(object.planDetailsConfirmed) ? Boolean(object.planDetailsConfirmed) : undefined,
      isNotMedicareSupplementPlan: isSet(object.isNotMedicareSupplementPlan)
        ? Boolean(object.isNotMedicareSupplementPlan)
        : undefined,
      doctorSpecifyNow: isSet(object.doctorSpecifyNow) ? Boolean(object.doctorSpecifyNow) : undefined,
      doctors: Array.isArray(object?.doctors) ? object.doctors.map((e: any) => Doctor.fromJSON(e)) : [],
      isCurrentlyPatient: isSet(object.isCurrentlyPatient) ? Boolean(object.isCurrentlyPatient) : undefined,
      paymentOption: isSet(object.paymentOption) ? String(object.paymentOption) : undefined,
    };
  },

  toJSON(message: EnrollmentPlanInfo): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.planDetailsConfirmed !== undefined && (obj.planDetailsConfirmed = message.planDetailsConfirmed);
    message.isNotMedicareSupplementPlan !== undefined &&
      (obj.isNotMedicareSupplementPlan = message.isNotMedicareSupplementPlan);
    message.doctorSpecifyNow !== undefined && (obj.doctorSpecifyNow = message.doctorSpecifyNow);
    if (message.doctors) {
      obj.doctors = message.doctors.map((e) => e ? Doctor.toJSON(e) : undefined);
    } else {
      obj.doctors = [];
    }
    message.isCurrentlyPatient !== undefined && (obj.isCurrentlyPatient = message.isCurrentlyPatient);
    message.paymentOption !== undefined && (obj.paymentOption = message.paymentOption);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentPlanInfo>, I>>(base?: I): EnrollmentPlanInfo {
    return EnrollmentPlanInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentPlanInfo>, I>>(object: I): EnrollmentPlanInfo {
    const message = createBaseEnrollmentPlanInfo();
    message.id = object.id ?? "";
    message.planDetailsConfirmed = object.planDetailsConfirmed ?? undefined;
    message.isNotMedicareSupplementPlan = object.isNotMedicareSupplementPlan ?? undefined;
    message.doctorSpecifyNow = object.doctorSpecifyNow ?? undefined;
    message.doctors = object.doctors?.map((e) => Doctor.fromPartial(e)) || [];
    message.isCurrentlyPatient = object.isCurrentlyPatient ?? undefined;
    message.paymentOption = object.paymentOption ?? undefined;
    return message;
  },
};

function createBaseEnrollmentReviewSubmit(): EnrollmentReviewSubmit {
  return { id: "", signatureType: 0 };
}

export const EnrollmentReviewSubmit = {
  encode(message: EnrollmentReviewSubmit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.signatureType !== 0) {
      writer.uint32(16).int32(message.signatureType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentReviewSubmit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentReviewSubmit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.signatureType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentReviewSubmit {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      signatureType: isSet(object.signatureType) ? enrollmentSignatureTypeFromJSON(object.signatureType) : 0,
    };
  },

  toJSON(message: EnrollmentReviewSubmit): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.signatureType !== undefined && (obj.signatureType = enrollmentSignatureTypeToJSON(message.signatureType));
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentReviewSubmit>, I>>(base?: I): EnrollmentReviewSubmit {
    return EnrollmentReviewSubmit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentReviewSubmit>, I>>(object: I): EnrollmentReviewSubmit {
    const message = createBaseEnrollmentReviewSubmit();
    message.id = object.id ?? "";
    message.signatureType = object.signatureType ?? 0;
    return message;
  },
};

function createBaseEnrollmentCompletedSteps(): EnrollmentCompletedSteps {
  return {
    prerequisites: undefined,
    applicantInfo: undefined,
    medicareInfo: undefined,
    planInfo: undefined,
    reviewSubmit: undefined,
  };
}

export const EnrollmentCompletedSteps = {
  encode(message: EnrollmentCompletedSteps, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prerequisites !== undefined) {
      writer.uint32(8).bool(message.prerequisites);
    }
    if (message.applicantInfo !== undefined) {
      writer.uint32(16).bool(message.applicantInfo);
    }
    if (message.medicareInfo !== undefined) {
      writer.uint32(24).bool(message.medicareInfo);
    }
    if (message.planInfo !== undefined) {
      writer.uint32(32).bool(message.planInfo);
    }
    if (message.reviewSubmit !== undefined) {
      writer.uint32(40).bool(message.reviewSubmit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EnrollmentCompletedSteps {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollmentCompletedSteps();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.prerequisites = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.applicantInfo = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.medicareInfo = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.planInfo = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.reviewSubmit = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EnrollmentCompletedSteps {
    return {
      prerequisites: isSet(object.prerequisites) ? Boolean(object.prerequisites) : undefined,
      applicantInfo: isSet(object.applicantInfo) ? Boolean(object.applicantInfo) : undefined,
      medicareInfo: isSet(object.medicareInfo) ? Boolean(object.medicareInfo) : undefined,
      planInfo: isSet(object.planInfo) ? Boolean(object.planInfo) : undefined,
      reviewSubmit: isSet(object.reviewSubmit) ? Boolean(object.reviewSubmit) : undefined,
    };
  },

  toJSON(message: EnrollmentCompletedSteps): unknown {
    const obj: any = {};
    message.prerequisites !== undefined && (obj.prerequisites = message.prerequisites);
    message.applicantInfo !== undefined && (obj.applicantInfo = message.applicantInfo);
    message.medicareInfo !== undefined && (obj.medicareInfo = message.medicareInfo);
    message.planInfo !== undefined && (obj.planInfo = message.planInfo);
    message.reviewSubmit !== undefined && (obj.reviewSubmit = message.reviewSubmit);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnrollmentCompletedSteps>, I>>(base?: I): EnrollmentCompletedSteps {
    return EnrollmentCompletedSteps.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnrollmentCompletedSteps>, I>>(object: I): EnrollmentCompletedSteps {
    const message = createBaseEnrollmentCompletedSteps();
    message.prerequisites = object.prerequisites ?? undefined;
    message.applicantInfo = object.applicantInfo ?? undefined;
    message.medicareInfo = object.medicareInfo ?? undefined;
    message.planInfo = object.planInfo ?? undefined;
    message.reviewSubmit = object.reviewSubmit ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
