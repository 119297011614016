import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EnrollmentActions } from './enrollment.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Enrollment } from 'src/app/shared/models';
import { Action } from '@ngrx/store';
import deleteUndefined from '../../../misc/deleteUndefined';

@Injectable()
export class EnrollmentEffects {
  loadEnrollments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.loadEnrollments),
      switchMap(() => {
        console.log('asd');
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'consumers', 'enrollments'),
            snapshot => {
              const enrollments = snapshot.docs.map(doc =>
                Enrollment.fromJSON({ ...doc.data(), id: doc.id })
              );
              subscriber.next(
                EnrollmentActions.loadEnrollmentsSuccess({ enrollments })
              );
            },
            error => {
              subscriber.next(
                EnrollmentActions.loadEnrollmentsFailure({ error })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Enrollment API] Load Enrollments Error', error })
          )
        );
      })
    );
  });

  loadEnrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.loadEnrollment),
      switchMap(({ enrollmentId, consumerId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'consumers',
              consumerId,
              'enrollments',
              enrollmentId
            ),
            snapshot => {
              const enrollment = Enrollment.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(
                EnrollmentActions.loadEnrollmentSuccess({ enrollment })
              );
            },
            error => {
              subscriber.next(
                EnrollmentActions.loadEnrollmentFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addEnrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.addEnrollment),
      mergeMap(async ({ consumerId, enrollment }) => {
        console.log('huhu');
        try {
          console.log('before add doc', consumerId);
          const docRef = await addDoc(
            collection(this.firestore, 'consumers', consumerId, 'enrollments'),
            enrollment
          );
          console.log('doc added', docRef);
          return EnrollmentActions.addEnrollmentSuccess({
            enrollment: Enrollment.fromJSON({ ...enrollment, id: docRef.id }),
          }); // return new enrollment with id
        } catch (error) {
          console.log('error', error);
          return EnrollmentActions.addEnrollmentFailure({ error });
        }
      })
    );
  });

  removeEnrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.removeEnrollment),
      mergeMap(async ({ consumerId, enrollmentId }) => {
        try {
          await deleteDoc(
            doc(
              this.firestore,
              'consumers',
              consumerId,
              'enrollments',
              enrollmentId
            )
          );
          return EnrollmentActions.removeEnrollmentSuccess({ enrollmentId }); // return removed enrollment's id
        } catch (error) {
          return EnrollmentActions.removeEnrollmentFailure({ error });
        }
      })
    );
  });

  updateEnrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentActions.updateEnrollment),
      mergeMap(async ({ consumerId, enrollmentId, enrollment }) => {
        try {
          await setDoc(
            doc(
              this.firestore,
              'consumers',
              consumerId,
              'enrollments',
              enrollmentId
            ),
            deleteUndefined(enrollment),
            {
              merge: true,
            }
          );
          return EnrollmentActions.updateEnrollmentSuccess({
            consumerId,
            enrollmentId,
            enrollment,
          }); // return updated enrollment's id and changes
        } catch (error) {
          return EnrollmentActions.updateEnrollmentFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
