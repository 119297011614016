import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EnrollmentSunfireActions } from './enrollment-sunfire.actions';
import {
  Firestore,
  collection,
  collectionGroup,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { SfWe_Plan } from 'src/app/shared/models';

@Injectable()
export class EnrollmentSunfireEffects {
  loadEnrollmentsSunfire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentSunfireActions.loadEnrollmentsSunfire),
      switchMap(({ consumerId, agentId }) => {
        console.log('consumerId', consumerId);
        return new Observable<Action>(subscriber => {
          let ref = consumerId
            ? collection(
                this.firestore,
                'consumers',
                consumerId,
                'enrollmentsSf'
              )
            : collectionGroup(this.firestore, 'enrollmentsSf');
          if (agentId) {
            ref = query(ref, where('agentId', '==', agentId));
          }
          const unsubscribe = onSnapshot(
            ref,
            snapshot => {
              const enrollmentsSunfire = snapshot.docs.map(doc =>
                SfWe_Plan.fromJSON({ ...doc.data(), id: doc.id })
              );
              subscriber.next(
                EnrollmentSunfireActions.loadEnrollmentsSunfireSuccess({
                  enrollmentsSunfire,
                })
              );
            },
            error => {
              console.error('error', error);
              subscriber.next(
                EnrollmentSunfireActions.loadEnrollmentsSunfireFailure({
                  error,
                })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error => {
            console.error('error', error);
            return of({
              type: '[EnrollmentSunfire] Load EnrollmentsSunfire Error',
              error,
            });
          })
        );
      })
    );
  });

  loadEnrollmentSunfire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentSunfireActions.loadEnrollmentSunfire),
      switchMap(({ enrollmentSunfireId, consumerId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'consumers',
              consumerId,
              'enrollmentsSf',
              enrollmentSunfireId
            ),
            snapshot => {
              const enrollmentSunfire = SfWe_Plan.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(
                EnrollmentSunfireActions.loadEnrollmentSunfireSuccess({
                  enrollmentSunfire,
                })
              );
            },
            error => {
              subscriber.next(
                EnrollmentSunfireActions.loadEnrollmentSunfireFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  // addEnrollmentSunfire$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(EnrollmentSunfireActions.addEnrollmentSunfire),
  //     mergeMap(async ({ consumerId, enrollmentSunfire }) => {
  //       try {
  //         const docRef = await addDoc(
  //           collection(this.firestore, 'consumers', consumerId, 'enrollmentsSf'),
  //           enrollmentSunfire
  //         );
  //         return EnrollmentSunfireActions.addEnrollmentSunfireSuccess({
  //           enrollmentSunfire: EnrollmentSunfire.fromJSON({ ...enrollmentSunfire, id: docRef.id }),
  //         }); // return new enrollmentSunfire with id
  //       } catch (error) {
  //         return EnrollmentSunfireActions.addEnrollmentSunfireFailure({ error });
  //       }
  //     })
  //   );
  // });

  // removeEnrollmentSunfire$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(EnrollmentSunfireActions.removeEnrollmentSunfire),
  //     mergeMap(async ({ consumerId, enrollmentSunfireId }) => {
  //       try {
  //         await deleteDoc(
  //           doc(
  //             this.firestore,
  //             'consumers',
  //             consumerId,
  //             'enrollmentsSf',
  //             enrollmentSunfireId
  //           )
  //         );
  //         return EnrollmentSunfireActions.removeEnrollmentSunfireSuccess({ enrollmentSunfireId }); // return removed enrollmentSunfire's id
  //       } catch (error) {
  //         return EnrollmentSunfireActions.removeEnrollmentSunfireFailure({ error });
  //       }
  //     })
  //   );
  // });

  updateEnrollmentSunfire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentSunfireActions.updateEnrollmentSunfire),
      mergeMap(
        async ({ consumerId, enrollmentSunfireId, enrollmentSunfire }) => {
          try {
            await setDoc(
              doc(
                this.firestore,
                'consumers',
                consumerId,
                'enrollmentsSf',
                enrollmentSunfireId
              ),
              SfWe_Plan.toJSON(enrollmentSunfire as SfWe_Plan) as any,
              {
                merge: true,
              }
            );
            return EnrollmentSunfireActions.updateEnrollmentSunfireSuccess({
              consumerId,
              enrollmentSunfireId,
              enrollmentSunfire,
            }); // return updated enrollmentSunfire's id and changes
          } catch (error) {
            return EnrollmentSunfireActions.updateEnrollmentSunfireFailure({
              error,
            });
          }
        }
      )
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
