import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AgencyActions } from './agency.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Agency } from 'src/app/shared/models';
import { Action } from '@ngrx/store';

@Injectable()
export class AgencyEffects {
  loadAgencies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.loadAgencies),
      mergeMap(() => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies'),
            snapshot => {
              const agencies = snapshot.docs.map(doc =>
                Agency.fromJSON({ ...doc.data(), id: doc.id })
              );
              subscriber.next(AgencyActions.loadAgenciesSuccess({ agencies }));
            },
            error => {
              subscriber.next(AgencyActions.loadAgenciesFailure({ error }));
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Agency API] Load Agencies Error', error })
          )
        );
      })
    );
  });

  loadAgency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.loadAgency),
      mergeMap(
        ({ agencyId }) =>
          new Observable<Action>(subscriber => {
            const unsubscribe = onSnapshot(
              doc(this.firestore, 'agencies', agencyId),
              snapshot => {
                if (!snapshot.exists()) {
                  subscriber.next(
                    AgencyActions.loadAgencyFailure({
                      error: 'Agency not found',
                    })
                  );
                  return;
                }
                const agency = Agency.fromJSON({
                  ...snapshot.data(),
                  id: snapshot.id,
                });
                subscriber.next(AgencyActions.loadAgencySuccess({ agency }));
              },
              error => {
                subscriber.next(AgencyActions.loadAgencyFailure({ error }));
              }
            );
            return unsubscribe;
          })
      )
    );
  });

  addAgency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.addAgency),
      mergeMap(async ({ agency }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies'),
            agency
          );
          return AgencyActions.addAgencySuccess({
            agency: Agency.fromJSON({ ...agency, id: docRef.id }),
          }); // return new agency with id
        } catch (error) {
          return AgencyActions.addAgencyFailure({ error });
        }
      })
    );
  });

  removeAgency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.removeAgency),
      mergeMap(async ({ agencyId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'agencies', agencyId));
          return AgencyActions.removeAgencySuccess({ agencyId }); // return removed agency's id
        } catch (error) {
          return AgencyActions.removeAgencyFailure({ error });
        }
      })
    );
  });

  updateAgency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.updateAgency),
      mergeMap(async ({ agencyId, agency }) => {
        try {
          await setDoc(doc(this.firestore, 'agencies', agencyId), agency, {
            merge: true,
          });
          return AgencyActions.updateAgencySuccess({
            agencyId,
            agency,
          }); // return updated agency's id and changes
        } catch (error) {
          return AgencyActions.updateAgencyFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
