import { createFeature, createReducer, on } from '@ngrx/store';
import { EnrollmentCompatActions } from './enrollment-compat.actions';
import { EnrollmentCompat } from 'src/app/shared/models/classes/enrollment-compat';

export const enrollmentCompatFeatureKey = 'enrollmentCompat';

export interface EnrollmentCompatState {
  enrollments: EnrollmentCompat[];
  selectedEnrollment: EnrollmentCompat | null;
  loading: boolean;
  error: any;
}

export const initialState: EnrollmentCompatState = {
  enrollments: [],
  selectedEnrollment: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    EnrollmentCompatActions.loadEnrollments,
    (state): EnrollmentCompatState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.loadEnrollmentsSuccess,
    (state, { enrollments }): EnrollmentCompatState => ({
      ...state,
      enrollments,
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.loadEnrollmentsFailure,
    (state, { error }): EnrollmentCompatState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentCompatActions.loadEnrollment,
    (state): EnrollmentCompatState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.loadEnrollmentSuccess,
    (state, { enrollment }): EnrollmentCompatState => ({
      ...state,
      enrollments: [enrollment],
      selectedEnrollment: enrollment,
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.loadEnrollmentFailure,
    (state, { error }): EnrollmentCompatState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentCompatActions.addEnrollment,
    (state): EnrollmentCompatState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.addEnrollmentSuccess,
    (state, { enrollment }): EnrollmentCompatState => ({
      ...state,

      enrollments: [...state.enrollments, enrollment],

      loading: false,

      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.addEnrollmentFailure,
    (state, { error }): EnrollmentCompatState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentCompatActions.removeEnrollment,
    (state): EnrollmentCompatState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.removeEnrollmentSuccess,
    (state, { enrollmentId }): EnrollmentCompatState => ({
      ...state,
      enrollments: state.enrollments.filter(
        enrollment => enrollment.id !== enrollmentId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.removeEnrollmentFailure,
    (state, { error }): EnrollmentCompatState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentCompatActions.updateEnrollment,
    (state): EnrollmentCompatState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.updateEnrollmentSuccess,
    (state, { enrollment }): EnrollmentCompatState => ({
      ...state,
      enrollments: state.enrollments.map(item =>
        // item.id === enrollment.id ? enrollment : item
        item.id === enrollment.id ? { ...item, ...enrollment } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentCompatActions.updateEnrollmentFailure,
    (state, { error }): EnrollmentCompatState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const enrollmentCompatFeature = createFeature({
  name: enrollmentCompatFeatureKey,
  reducer,
});
