import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EnrollmentCompatActions } from './enrollment-compat.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import deleteUndefined from '../../../misc/deleteUndefined';
import { EnrollmentCompat } from 'src/app/shared/models/classes/enrollment-compat';

@Injectable()
export class EnrollmentCompatEffects {
  loadEnrollments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentCompatActions.loadEnrollments),
      switchMap(() => {
        console.log('asd');
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'consumers', 'enrollmentsCompat'),
            snapshot => {
              const enrollments = snapshot.docs.map(doc =>
                EnrollmentCompat.fromJSON({ ...doc.data(), id: doc.id })
              );
              subscriber.next(
                EnrollmentCompatActions.loadEnrollmentsSuccess({ enrollments })
              );
            },
            error => {
              subscriber.next(
                EnrollmentCompatActions.loadEnrollmentsFailure({ error })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({ type: '[Enrollment API] Load Enrollments Error', error })
          )
        );
      })
    );
  });

  loadEnrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentCompatActions.loadEnrollment),
      switchMap(({ enrollmentId, consumerId }) => {
        return new Observable<Action>(subscriber => {
          const unsubscribe = onSnapshot(
            doc(
              this.firestore,
              'consumers',
              consumerId,
              'enrollmentsCompat',
              enrollmentId
            ),
            snapshot => {
              const enrollment = EnrollmentCompat.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
              });
              subscriber.next(
                EnrollmentCompatActions.loadEnrollmentSuccess({ enrollment })
              );
            },
            error => {
              subscriber.next(
                EnrollmentCompatActions.loadEnrollmentFailure({ error })
              );
            }
          );
          return unsubscribe;
        });
      })
    );
  });

  addEnrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentCompatActions.addEnrollment),
      mergeMap(async ({ consumerId, enrollment }) => {
        console.log('huhu');
        try {
          console.log('before add doc', consumerId);
          const docRef = await addDoc(
            collection(
              this.firestore,
              'consumers',
              consumerId,
              'enrollmentsCompat'
            ),
            enrollment
          );
          console.log('doc added', docRef);
          return EnrollmentCompatActions.addEnrollmentSuccess({
            enrollment: EnrollmentCompat.fromJSON({
              ...enrollment,
              id: docRef.id,
            }),
          }); // return new enrollment with id
        } catch (error) {
          console.log('error', error);
          return EnrollmentCompatActions.addEnrollmentFailure({ error });
        }
      })
    );
  });

  removeEnrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentCompatActions.removeEnrollment),
      mergeMap(async ({ consumerId, enrollmentId }) => {
        try {
          await deleteDoc(
            doc(
              this.firestore,
              'consumers',
              consumerId,
              'enrollmentsCompat',
              enrollmentId
            )
          );
          return EnrollmentCompatActions.removeEnrollmentSuccess({
            enrollmentId,
          }); // return removed enrollment's id
        } catch (error) {
          return EnrollmentCompatActions.removeEnrollmentFailure({ error });
        }
      })
    );
  });

  updateEnrollment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(EnrollmentCompatActions.updateEnrollment),
      mergeMap(async ({ consumerId, enrollmentId, enrollment }) => {
        try {
          await setDoc(
            doc(
              this.firestore,
              'consumers',
              consumerId,
              'enrollmentsCompat',
              enrollmentId
            ),
            deleteUndefined(enrollment),
            {
              merge: true,
            }
          );
          return EnrollmentCompatActions.updateEnrollmentSuccess({
            consumerId,
            enrollmentId,
            enrollment,
          }); // return updated enrollment's id and changes
        } catch (error) {
          return EnrollmentCompatActions.updateEnrollmentFailure({ error });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore
  ) {}
}
