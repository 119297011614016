import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { getWindow } from 'ssr-window';

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.sentry.environment,
  integrations: [
    Sentry.browserTracingIntegration({
      // tracePropagationTargets: environment.sentry.tracePropagationTargets,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: event => {
    const window = getWindow();
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch(err => console.error(err));

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
