import { createFeature, createReducer, on } from '@ngrx/store';
import { EnrollmentActions } from './enrollment.actions';
import { Enrollment } from 'src/app/shared/models';

export const enrollmentFeatureKey = 'enrollment';

export interface EnrollmentState {
  enrollments: Enrollment[];
  enrollment: Enrollment | null;
  loading: boolean;
  error: any;
}

export const initialState: EnrollmentState = {
  enrollments: [],
  enrollment: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    EnrollmentActions.loadEnrollments,
    (state): EnrollmentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentActions.loadEnrollmentsSuccess,
    (state, { enrollments }): EnrollmentState => ({
      ...state,
      enrollments,
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentActions.loadEnrollmentsFailure,
    (state, { error }): EnrollmentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentActions.loadEnrollment,
    (state): EnrollmentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentActions.loadEnrollmentSuccess,
    (state, { enrollment }): EnrollmentState => ({
      ...state,
      enrollments: [enrollment],
      enrollment: enrollment,
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentActions.loadEnrollmentFailure,
    (state, { error }): EnrollmentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentActions.addEnrollment,
    (state): EnrollmentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentActions.addEnrollmentSuccess,
    (state, { enrollment }): EnrollmentState => ({
      ...state,

      enrollments: [...state.enrollments, enrollment],

      loading: false,

      error: null,
    })
  ),
  on(
    EnrollmentActions.addEnrollmentFailure,
    (state, { error }): EnrollmentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentActions.removeEnrollment,
    (state): EnrollmentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentActions.removeEnrollmentSuccess,
    (state, { enrollmentId }): EnrollmentState => ({
      ...state,
      enrollments: state.enrollments.filter(
        enrollment => enrollment.id !== enrollmentId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentActions.removeEnrollmentFailure,
    (state, { error }): EnrollmentState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    EnrollmentActions.updateEnrollment,
    (state): EnrollmentState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    EnrollmentActions.updateEnrollmentSuccess,
    (state, { enrollment }): EnrollmentState => ({
      ...state,
      enrollments: state.enrollments.map(item =>
        // item.id === enrollment.id ? enrollment : item
        item.id === enrollment.id ? { ...item, ...enrollment } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    EnrollmentActions.updateEnrollmentFailure,
    (state, { error }): EnrollmentState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const enrollmentFeature = createFeature({
  name: enrollmentFeatureKey,
  reducer,
});
